export var ServiceType;
(function (ServiceType) {
    ServiceType["Bankruptcy"] = "qBk4RZd6XLMQ";
    ServiceType["Collections"] = "1VWn85Z6wlOd";
    ServiceType["FamilyLaw"] = "eDO5GE38yVL3";
    ServiceType["LocalCounsel"] = "jMAb6O4G7PyZ";
    ServiceType["Probate"] = "pgq2GK3GjoDe";
    ServiceType["Civil"] = "qaP18BpGVJ25";
    ServiceType["Criminal"] = "qPjbGrl8Lz3Y";
    ServiceType["Immigration"] = "ErqV8jxGyX15";
    ServiceType["ProHacVice"] = "M1JDG73GqBZK";
    ServiceType["WorkerCompensation"] = "qPkr6y483VXD";
    ServiceType["VirtualAppearance"] = "3mlo8lL84B2J";
})(ServiceType || (ServiceType = {}));
